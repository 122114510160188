<template>
  <div>
    <div id="container-imprint">
      <b-button
        variant="primary"
        id="back"
        class="rounded-circle px-2 left-top"
        @click="routerGoBack()"
      >
        <b-icon icon="chevron-left" scale="1"></b-icon>
      </b-button>
      <h3>Impressum</h3>
      <h2>Informationen gemäß §§ 5, 6 TMG, 55 II RStV</h2>
      Betreiber und inhaltlich Verantwortliche im Sinne des § 7 TMG ist
      <br />
      <br />
      <h4>digital animals GmbH</h4>
      <br />
      <br />
      <h4>Geschäftsführer:</h4>
      Daniel Terstegge
      <br />
      David van den Boom
      <br />
      Torsten Baschwitz
      <br />
      <br />
      <h4>Sitz der Gesellschaft:</h4>
      Essen
      <h4>Registergericht:</h4>
      Amtsgericht Essen
      <br />
      HRB 32190
      <br />
      <br />
      Katernberger Straße 107
      <br />
      45327 Essen
      <br />
      <br />
      Email: bee@digtalanimals.de
      <br />
      Fon: +49 (0) 178 2654 355
      <br />
      www.digitalanimals.de
      <br />
      <br />
      <h4>Steuernummer</h4>
      111/5726/2545
      <h4>Umsatzsteuer ID</h4>
      DE343294201
      <br />
      <br />
      <br />
      <br />
      <br />
      <h4>Haftung für Inhalte</h4>
      Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf
      diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis
      10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
      übermittelte oder gespeicherte fremde Informationen zu überwachen oder
      nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
      hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
      Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
      Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis
      einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
      entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
      entfernen.
      <br />
      <br />
      <h4>Haftung für Links</h4>
      Unser Angebot enthält Links zu externen Websites Dritter, auf deren
      Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
      Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
      Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
      verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung
      auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
      Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche
      Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
      einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
      Rechtsverletzungen werden wir derartige Links umgehend entfernen.
      <br />
      <br />
      <h4>Urheberrecht</h4>
      Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
      Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
      Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen
      des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen
      Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nicht
      gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber
      erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
      werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
      eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
      entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
      wir derartige Inhalte umgehend entfernen.
      <br />
      <br />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
import store from '../store'

export default {
  name: 'Imprint',
  methods: {
    routerGoBack () {
      if (this.fullscreenState) {
        this.toggleFullscreen()
      }
      // this.$router.go(-1);
      this.$router.push({ path: '/#' + store.state.activeHomeViewHash })
    }
  }
}
</script>
<style scoped lang="scss">
#container-imprint {
  width: 100vw;
  height: 100vh;
  position: absolute;
  text-align: left;
  padding: 100px;
  margin-bottom: 100px;
  top: 0;
}
.left-top {
  position: fixed;
  top: 100px;
  left: 40px;
  z-index: 25;
}
.btn-primary {
  color: black;
  background-color: #00ffd6;
  border-color: #00ffd6;
}
.btn-primary:hover {
  color: #212529;
  background-color: #00977e;
  border-color: #00977e;
}
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none;
  color: black;
  background-color: #00ffd6;
  border-color: #00ffd6;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #212529;
  background-color: #00977e;
  border-color: #00977e;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #00977e77;
  color: black;
  background-color: #00ffd6;
  border-color: #00ffd6;
}
</style>
